/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

function SEO({ description, lang, urlStub = "", meta, titleStub, image }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            author
            description
            image
            title
            twitterUsername
            url
            instagramUsername
            googleTagManager
            googleConsole
            impactSiteVerification
          }
        }
      }
    `
  );

  const { siteMetadata } = site;
  const {
    description: siteDescription,
    title,
    url,
    googleTagManager,
    googleConsole,
    impactSiteVerification,
  } = siteMetadata;

  const metaDescription = description || siteDescription;
  const metaTitle = titleStub ? `${titleStub} | ${title}` : title;
  const updatedUrlStub = urlStub.endsWith("/") ? urlStub : urlStub + "/";

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={titleStub ? titleStub : title}
      titleTemplate={`%s`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:site_name`,
          content: title,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: "og:url",
          content: `${url}${updatedUrlStub}`,
        },
        // {
        //   property: `fb:app_id`,
        //   content: "176656027020247",
        // },
        {
          property: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          property: `og:image`,
          content: image,
        },
        {
          property: `og:locale`,
          content: `en_US`,
        },
        {
          property: `twitter:title`,
          content: metaTitle,
        },
        {
          property: `twitter:image`,
          content: image,
        },
        {
          property: `twitter:site`,
          content: `@sleeplessyogi`,
        },
        {
          property: `twitter:creator`,
          content: `@sleeplessyogi`,
        },
        {
          name: `google-site-verification`,
          content: googleConsole,
        },
        {
          name: `impact-site-verification`,
          content: impactSiteVerification,
        },
        {
          name: `google-site-verification`,
          content: googleTagManager,
        },
        {
          property: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `robots`,
          content: `index`,
        },
      ].concat(meta)}
    >
      <link
        href="https://fonts.googleapis.com/css2?family=Montserrat&display=swap"
        rel="stylesheet"
      ></link>
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/icon?family=Material+Icons"
      />
      <link rel="canonical" href={`${url}${updatedUrlStub}`} />
      {/* <script async src="https://gumroad.com/js/gumroad.js"></script> */}
      <script src="https://cdn.tailwindcss.com/3.3.5"></script>
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  titleStub: PropTypes.string,
  image: PropTypes.string,
};

export default SEO;
